import React from 'react';
import { Button, Flex, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../../redux/authSlice';


const Custom500: React.FC = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    
    return (
        <Result
            status="500"
            title="500"
            subTitle="Neočekávaná chyba aplikace."
            extra={<Flex vertical align='center' gap="middle">
                <Button type="primary" onClick={() => navigation('/secured/dashboard')}>Zpět na dashboard</Button>
                <Button onClick={() => dispatch(logout())}>Odhlásit se</Button>
                </Flex>}
        />
    );
};

export default Custom500;