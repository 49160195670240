import { AsyncThunkConfig } from '@reduxjs/toolkit/dist/createAsyncThunk';
import SecurityApi from '../api/securityApi';
import { createMessage } from '../utils/common_utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthDTO, LoginParams } from '../types/common';


let emplLogin: ReturnType<typeof createAsyncThunk<AuthDTO, LoginParams, AsyncThunkConfig>>;

export function inicializeEmplLogin() {
    emplLogin = createAsyncThunk(
        'auth/login',
        async (_data: LoginParams, {rejectWithValue}) => {
            const { data, status, message } = (await SecurityApi.login(_data)).data;
            if (status !== 'success') {
                createMessage('warning', message);
                return rejectWithValue(message);
            }
            return data;
        }
      );
}

export function getEmplLogin() {
    return emplLogin;
}