import { createSlice } from '@reduxjs/toolkit';
import { NavigateState } from '../types/common';

const initialState: NavigateState = {
    path: undefined
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigate: (state, action) => {
      state.path = action.payload;
    }
  }
});

export const { setNavigate } = navigationSlice.actions;
export default navigationSlice.reducer;