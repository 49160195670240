import { AxiosPromise } from 'axios';
import { ApiResponseEntity, AuthDTO, LoginParams } from '../types/common';

import axios from '../utils/axios';

const API_PATH = '/';

export default class SecurityApi {
    static login(data: LoginParams): AxiosPromise<ApiResponseEntity<AuthDTO>> {
        return axios.post(API_PATH, data);
    }
}